import React from "react"
import classNames from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import dayjs from "dayjs"
import "dayjs/locale/ja"
import "./index.css"

export default ({ date, modified, size }) => {
  const sizeClass = size ? "is-" + size : ""
  const dateClasses = classNames("date", sizeClass)
  return (
    <div className={dateClasses}>
      <div className="date-content">
        <FontAwesomeIcon className="date-icon" icon={["far", "calendar-alt"]} />
        <time className="date-time">{dayjs(date).format("YYYY.MM.DD")}</time>
      </div>
      {modified && (
        <div className="date-content">
          <FontAwesomeIcon className="date-icon" icon={["fas", "history"]} />
          <time className="date-time">
            {dayjs(modified).format("YYYY.MM.DD")}
          </time>
        </div>
      )}
    </div>
  )
}
