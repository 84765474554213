import React from "react"
import classNames from "classnames"
import "./index.css"

export default ({ media, sameHeight, list, min, max, gap, children }) => {
  const mediaClass = media ? "is-" + media + "-active" : ""
  const sameHeightClass = sameHeight ? "is-same-height" : ""
  const gridClasses = classNames("grid", mediaClass, sameHeightClass)
  const templateColumnsStyle = (() => {
    if (list) {
      return list
    } else if (min && max) {
      return "repeat(auto-fit, minmax(" + min + ", " + max + "))"
    } else {
      return ""
    }
  })()
  const gapStyle = gap ? gap : ""
  const gridStyles = {
    gridTemplateColumns: templateColumnsStyle,
    gap: gapStyle,
  }
  return (
    <ul className={gridClasses} style={gridStyles}>
      {children}
    </ul>
  )
}
