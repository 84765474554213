import React from "react"
import parse from "html-react-parser"
import classNames from "classnames"
import "./index.css"

export default ({ content, level, align }) => {
  const levelClass = level ? "is-level-" + level : ""
  const alignClass = align ? "is-" + align : ""
  const coreHeadingClasses = classNames("core-heading", levelClass, alignClass)
  return React.createElement(
    `h${level}`,
    { className: coreHeadingClasses },
    parse(content)
  )
}
