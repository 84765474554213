import React from "react"
import parse from "html-react-parser"
import classNames from "classnames"
import Image from "gatsby-image"
import "./index.css"

export default ({
  modifier,
  avatarAlt,
  avatarBorderColor,
  balloonName,
  balloonBody,
  localFile: {
    childImageSharp: { fluid },
  },
}) => {
  const modifierClass = modifier ? "is-" + modifier : ""
  const smbBalloonClasses = classNames("smb-baloon", modifierClass)
  const avatarBorderColorStyle = avatarBorderColor ? avatarBorderColor : ""
  const smbBalloonAvatarImageStyle = {
    borderColor: avatarBorderColorStyle,
  }
  return (
    <div className={smbBalloonClasses}>
      <div className={"smb-balloon-avatar"}>
        {fluid && (
          <div
            className="smb-balloon-avatar-image"
            style={smbBalloonAvatarImageStyle}
          >
            <Image
              className={`smb-balloon-avatar-image-media`}
              fluid={fluid}
              alt={avatarAlt}
            />
          </div>
        )}
        {balloonName && (
          <div className={"smb-balloon-avatar-name"}>{parse(balloonName)}</div>
        )}
      </div>
      <div className="smb-balloon-voice">
        {parse(balloonBody)}
        <span className="smb-balloon-voice-tail"></span>
      </div>
    </div>
  )
}
