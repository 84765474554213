import React from "react"
import classNames from "classnames"
import "./index.css"

export default ({ coreSpacerAttributes }) => {
  const coreSpacerClasses = classNames("core-spacer")
  const coreSpacerStyles = {
    height: coreSpacerAttributes.height,
  }
  return <div className={coreSpacerClasses} style={coreSpacerStyles} />
}
