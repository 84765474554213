import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import Image from "gatsby-image"
import classNames from "classnames"
import "./index.css"

export default ({ cover, url, title, description, here }) => {
  const hereClass = here ? "is-here" : ""
  const mediaClasses = classNames("media", hereClass)
  const mediaCoverBoxClasses = classNames("media-cover-box")
  return (
    <StaticQuery
      query={graphql`
        query MediaQuery {
          noimage: file(name: { eq: "noimage" }) {
            childImageSharp {
              fluid(maxHeight: 60) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={(data) => {
        const noimage = data.noimage.childImageSharp.fluid
        return (
          <Link
            className={mediaClasses}
            to={url}
            activeClassName="is-active"
            partiallyActive={true}
          >
            <div className={mediaCoverBoxClasses}>
              <div className="media-cover-wrap">
                {cover && cover.localFile ? (
                  <Image
                    className={`media-cover`}
                    fluid={cover.localFile.childImageSharp.fluid}
                  />
                ) : (
                  <Image className={`media-cover`} fluid={noimage} />
                )}
              </div>
            </div>
            <div className="media-meta-box">
              <p className="media-title">{title}</p>
              {description && (
                <p className="media-description">{description}</p>
              )}
            </div>
          </Link>
        )
      }}
    />
  )
}
