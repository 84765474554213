import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import parse from "html-react-parser"
import classNames from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import "./index.css"

export default ({
  text,
  url,
  linkTarget,
  rel,
  buttonType,
  maxWidth,
  maxUnit,
  paddingType,
  backgroundColor,
  customBackgroundColor,
  borderRadius,
  textColor,
  customTextColor,
  fontSize,
  customFontSize,
  align,
  iconLeft,
  iconRight,
  iconSizeLeft,
  iconSizeRight,
}) => {
  return (
    <StaticQuery
      query={graphql`
        query YstdbButton {
          wpgraphql {
            generalSettings {
              url
            }
            appOptions {
              global {
                url
              }
            }
          }
        }
      `}
      render={(data) => {
        const devUrl = data.wpgraphql.generalSettings.url
        const appUrl = data.wpgraphql.appOptions.global.url
        const alignClass = align ? "is-" + align : ""
        const ystdbButtonWrapClasses = classNames(
          "ystdb-button-wrap",
          alignClass
        )
        const backgroundColorClass = backgroundColor
          ? "is-bg-" + backgroundColor
          : ""
        const textColorClass = textColor ? "is-color-" + textColor : ""
        const fontSizeClass = fontSize ? "is-font-size-" + fontSize : ""
        const ystdbButtonClasses = classNames(
          "ystdb-button",
          buttonType,
          paddingType,
          backgroundColorClass,
          textColorClass,
          fontSizeClass
        )
        const ystdbButtonStyles = {
          maxWidth: maxWidth + maxUnit,
          backgroundColor: customBackgroundColor,
          borderRadius: borderRadius,
          color: customTextColor,
          fontSize: customFontSize,
        }
        return (
          <div className={ystdbButtonWrapClasses}>
            {(() => {
              //console.log(url)
              //console.log(devUrl)
              //console.log(appUrl)
              if (url.match(devUrl) || url.match(appUrl)) {
                const parseUrl = new URL(url)
                const parseUrlPathname = parseUrl.pathname
                return (
                  <Link
                    to={parseUrlPathname}
                    className={ystdbButtonClasses}
                    style={ystdbButtonStyles}
                  >
                    {iconLeft === "arrow-right-circle" ? (
                      <FontAwesomeIcon
                        className="icon"
                        icon={["far", "arrow-alt-circle-right"]}
                      />
                    ) : (
                      ""
                    )}
                    <span className={"ystdb-button-text"}>{parse(text)}</span>
                  </Link>
                )
              } else if (!url.indexOf("./")) {
                //const inUrl = url.slice(1)
                const subUrl = url.replace(/.\//g, "../")
                return (
                  <Link
                    to={subUrl}
                    className={ystdbButtonClasses}
                    style={ystdbButtonStyles}
                  >
                    {iconLeft === "arrow-right-circle" ? (
                      <FontAwesomeIcon
                        className="icon"
                        icon={["far", "arrow-alt-circle-right"]}
                      />
                    ) : (
                      ""
                    )}
                    <span className={"ystdb-button-text"}>{parse(text)}</span>
                  </Link>
                )
              } else if (!url.indexOf("/")) {
                //const inUrl = url.slice(1)
                //const subUrl = url.replace(/.\//g, "../")
                return (
                  <Link
                    to={url}
                    className={ystdbButtonClasses}
                    style={ystdbButtonStyles}
                  >
                    {iconLeft === "arrow-right-circle" ? (
                      <FontAwesomeIcon
                        className="icon"
                        icon={["far", "arrow-alt-circle-right"]}
                      />
                    ) : (
                      ""
                    )}
                    <span className={"ystdb-button-text"}>{parse(text)}</span>
                  </Link>
                )
              } else {
                return (
                  <a
                    href={url}
                    target={linkTarget}
                    rel={rel}
                    className={ystdbButtonClasses}
                    style={ystdbButtonStyles}
                  >
                    {iconLeft === "arrow-right-circle" ? (
                      <FontAwesomeIcon
                        className="icon"
                        icon={["far", "arrow-alt-circle-right"]}
                      />
                    ) : (
                      ""
                    )}
                    <span className={"ystdb-button-text"}>{parse(text)}</span>
                  </a>
                )
              }
            })()}
          </div>
        )
      }}
    />
  )
}
