import React from "react"
import parse, { domToReact } from "html-react-parser"
import classNames from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import "./index.css"

export default ({ content, icon, iconColor }) => {
  const listTag = "ul"
  const smbListClasses = classNames("smb-list")
  const smbListIconStyle = {
    color: iconColor,
  }
  const options = {
    replace: ({ attribs, children }) => {
      if (!attribs) return
      if (icon) {
        if ("<li></li>") {
          return (
            <li>
              <FontAwesomeIcon
                className="icon"
                icon={["fas", icon]}
                style={smbListIconStyle}
              />
              {domToReact(children)}
            </li>
          )
        }
      }
    },
  }
  if (!content) {
    return null
  }
  return React.createElement(
    listTag,
    { className: smbListClasses },
    parse(content, options)
  )
}
