import React from "react"
import parse from "html-react-parser"
import classNames from "classnames"
import "./index.css"

export default ({ value, citation, align }) => {
  const alignClass = align ? "is-" + align : ""
  const coreQuoteClasses = classNames("core-quote", alignClass)
  return (
    <blockquote className={coreQuoteClasses}>
      <div className="core-quote-inner">{parse(value)}</div>
      {citation && <cite>{citation}</cite>}
    </blockquote>
  )
}
