import React from "react"
import parse from "html-react-parser"
import classNames from "classnames"
import "./index.css"

export default ({ originalContent }) => {
  const coreHeadingClasses = classNames("core-html")
  return React.createElement(
    `div`,
    { className: coreHeadingClasses },
    parse(originalContent)
  )
}
