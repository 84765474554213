import React from "react"
import parse from "html-react-parser"
import classNames from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import "./index.css"

export default ({
  content,
  align,
  fontSize,
  textColor,
  backgroundColor,
  customFontSize,
  customTextColor,
  customBackgroundColor,
  className,
}) => {
  const alignClass = align ? "is-" + align : ""
  const mergeFontSizeClass = (() => {
    if (customFontSize) {
      return "is-" + customFontSize
    } else if (fontSize) {
      return "is-" + fontSize
    } else {
      return ""
    }
  })()
  const colorName = customTextColor
    ? customTextColor
    : textColor
    ? textColor
    : ""
  const hasTextColorClass = colorName ? "is-color-" + colorName : ""
  const hasBackgroundColorClass =
    backgroundColor || customBackgroundColor ? "is-padding-md" : ""
  const coreParagraphClasses = classNames(
    "core-paragraph",
    alignClass,
    mergeFontSizeClass,
    hasTextColorClass,
    hasBackgroundColorClass,
    className
  )
  const mergeTextColorStyle = (() => {
    if (customTextColor) {
      return customTextColor
    } else if (textColor) {
      return textColor
    } else {
      return ""
    }
  })()
  const mergeBackgroundColorStyle = (() => {
    if (customBackgroundColor) {
      return customBackgroundColor
    } else if (backgroundColor) {
      return backgroundColor
    } else {
      return ""
    }
  })()
  const coreParagraphStyles = {
    color: mergeTextColorStyle,
    backgroundColor: mergeBackgroundColorStyle,
  }
  return (
    <p className={coreParagraphClasses} style={coreParagraphStyles}>
      {className === "is-style-sme-alert" ? (
        <FontAwesomeIcon
          className="icon"
          icon={["fas", "exclamation-triangle"]}
        />
      ) : (
        ""
      )}
      {parse(content)}
    </p>
  )
}
