import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import Image from "gatsby-image"
import classNames from "classnames"
import "./index.css"

export default ({ qrokoBlogCardAttributes }) => {
  const url = qrokoBlogCardAttributes.url
  const title = qrokoBlogCardAttributes.title
  const description = qrokoBlogCardAttributes.description
  const imageAlt = qrokoBlogCardAttributes.imageAlt
  const imagePosition = qrokoBlogCardAttributes.imagePosition
  const imageFit = qrokoBlogCardAttributes.imageFit
  const ogDomain = qrokoBlogCardAttributes.ogDomain
  const ogImageURL = qrokoBlogCardAttributes.ogImageURL
  const localFile = qrokoBlogCardAttributes.localFile
  return (
    <StaticQuery
      query={graphql`
        query StaticQrokoBlogCard {
          wpgraphql {
            appOptions {
              global {
                url
              }
            }
          }
        }
      `}
      render={(data) => {
        const openGraphUrl = new URL(url)
        const openGraphDomain = openGraphUrl.hostname
        const currentDomain = data.wpgraphql.appOptions.global.url
        const qrokoBlogCardClasses = classNames("qroko-blocks-blog-card")
        return (
          <div className={qrokoBlogCardClasses}>
            {openGraphDomain !== currentDomain ? (
              <a
                href={url}
                target="_blank"
                rel="noopener noreferrer"
                className="qroko-blocks-blog-card-link"
              >
                {" "}
              </a>
            ) : (
              <Link to={url} className="qroko-blocks-blog-card-link" />
            )}
            <div
              className={classNames(
                "qroko-blocks-blog-card-columns",
                imagePosition === "right" ? "is-reverse" : ""
              )}
            >
              {(localFile || ogImageURL) && (
                <div className="qroko-blocks-blog-card-column is-flex-none">
                  <div className="qroko-blocks-blog-card-image-container">
                    <div className="qroko-blocks-blog-card-image-wrap">
                      {(() => {
                        if (localFile && localFile.childImageSharp) {
                          return (
                            <Image
                              className={classNames(
                                "qroko-blocks-blog-card-image",
                                "is-" + imageFit
                              )}
                              fluid={localFile.childImageSharp.fluid}
                              alt={imageAlt}
                            />
                          )
                        } else if (localFile) {
                          return (
                            <img
                              className={classNames(
                                "qroko-blocks-blog-card-image",
                                "is-" + imageFit
                              )}
                              src={localFile.publicURL}
                              alt={imageAlt}
                            />
                          )
                        } else if (ogImageURL) {
                          return (
                            <img
                              className={classNames(
                                "qroko-blocks-blog-card-image",
                                "is-" + imageFit
                              )}
                              src={ogImageURL}
                              alt={imageAlt}
                            />
                          )
                        }
                      })()}
                    </div>
                  </div>
                </div>
              )}
              <div className="qroko-blocks-blog-card-column is-padding is-flex-grow">
                <div className="qroko-blocks-blog-card-meta">
                  {title && (
                    <div className="qroko-blocks-blog-card-heading">
                      {title}
                    </div>
                  )}
                  {description && (
                    <div className="qroko-blocks-blog-card-description">
                      {description}
                    </div>
                  )}
                  {ogDomain && (
                    <div className="qroko-blocks-blog-card-domain">
                      {ogDomain}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )
      }}
    />
  )
}
