import React from "react"
import parse from "html-react-parser"
import classNames from "classnames"
import CoreParagraph from "../../core/paragraph"
import CoreImage from "../../core/image"
import CoreHeading from "../../core/heading"
import CoreList from "../../core/list"
import CoreQuote from "../../core/quote"
import CoreSeparator from "../../core/separator"
import CoreSpacer from "../../core/spacer"
import CoreHtml from "../../core/html"
import QrokoCard from "../../qroko/blog-card"
import SmbAlert from "../alert"
import SmbList from "../list"
import SmbBalloon from "../balloon"
import YstdbHeading from "../../ystdb/heading"
import YstdbButton from "../../ystdb/button"
import YstdbBalloon from "../../ystdb/balloon"
//import YstdbCard from "../../ystdb/card"
import "./index.css"

export default ({ innerBlocks, initialState, title }) => {
  const smbAccordionItemClasses = classNames("smb-accordion-item")
  const blockMap = {
    "core/paragraph": CoreParagraph,
    "core/image": CoreImage,
    "core/heading": CoreHeading,
    "core/list": CoreList,
    "core/quote": CoreQuote,
    "core/separator": CoreSeparator,
    "core/spacer": CoreSpacer,
    "core/html": CoreHtml,
    "qroko-blocks/blog-card": QrokoCard,
    "snow-monkey-blocks/alert": SmbAlert,
    "snow-monkey-blocks/list": SmbList,
    "snow-monkey-blocks/balloon": SmbBalloon,
    "ystdb/heading": YstdbHeading,
    "ystdb/svg-button": YstdbButton,
    "ystdb/balloon": YstdbBalloon,
    //"ystdb/card": YstdbCard,
  }
  return (
    <details className={smbAccordionItemClasses} open={initialState}>
      <summary className="smb-accordion-item-summary">{parse(title)}</summary>
      <div className="smb-accordion-item-body">
        {innerBlocks &&
          innerBlocks.map((data, index) => {
            const Component = blockMap[data.name] || false

            if (!Component) {
              return null
            }

            return (
              <Component
                key={`${data.name}-${index}`}
                {...data.attributes}
                coreSpacerAttributes={data.coreSpacerAttributes}
                qrokoBlogCardAttributes={data.qrokoBlogCardAttributes}
                ystdbHeadingAttributes={data.ystdbHeadingAttributes}
                originalContent={data.originalContent}
                dynamicContent={data.dynamicContent}
              />
            )
          })}
      </div>
    </details>
  )
}
