import React from "react"
import classNames from "classnames"
import CoreParagraph from "../paragraph"
import CoreImage from "../image"
import CoreHeading from "../heading"
import CoreList from "../list"
import CoreQuote from "../quote"
import CoreSeparator from "../separator"
import CoreSpacer from "../spacer"
import CoreHtml from "../html"
import QrokoCard from "../../qroko/blog-card"
import SmbAlert from "../../smb/alert"
import SmbList from "../../smb/list"
import SmbBalloon from "../../smb/balloon"
import SmbBox from "../../smb/box"
import YstdbHeading from "../../ystdb/heading"
import YstdbButton from "../../ystdb/button"
import YstdbBalloon from "../../ystdb/balloon"
//import YstdbCard from "../../ystdb/card"
import "./index.css"

export default ({ innerBlocks, width }) => {
  const coreColumnClasses = classNames("core-column")
  const coreColumnStyles = {
    flexBasis: width + "%",
  }
  const blockMap = {
    "core/paragraph": CoreParagraph,
    "core/image": CoreImage,
    "core/heading": CoreHeading,
    "core/list": CoreList,
    "core/quote": CoreQuote,
    "core/separator": CoreSeparator,
    "core/spacer": CoreSpacer,
    "core/html": CoreHtml,
    "qroko-blocks/blog-card": QrokoCard,
    "snow-monkey-blocks/alert": SmbAlert,
    "snow-monkey-blocks/list": SmbList,
    "snow-monkey-blocks/balloon": SmbBalloon,
    "snow-monkey-blocks/box": SmbBox,
    "ystdb/heading": YstdbHeading,
    "ystdb/svg-button": YstdbButton,
    "ystdb/balloon": YstdbBalloon,
    //"ystdb/card": YstdbCard,
  }
  return (
    <div className={coreColumnClasses} style={coreColumnStyles}>
      {innerBlocks &&
        innerBlocks.map((data, index) => {
          const Component = blockMap[data.name] || false

          if (!Component) {
            return null
          }

          return (
            <Component
              key={`${data.name}-${index}`}
              {...data.attributes}
              coreSpacerAttributes={data.coreSpacerAttributes}
              qrokoBlogCardAttributes={data.qrokoBlogCardAttributes}
              ystdbHeadingAttributes={data.ystdbHeadingAttributes}
              originalContent={data.originalContent}
              dynamicContent={data.dynamicContent}
            />
          )
        })}
    </div>
  )
}
