import React from "react"
import classNames from "classnames"
import CoreColumn from "../column"
import "./index.css"

export default ({ innerBlocks }) => {
  const coreColumnsClasses = classNames("core-columns")
  const blockMap = {
    "core/column": CoreColumn,
  }
  return (
    <div className={coreColumnsClasses}>
      {innerBlocks &&
        innerBlocks.map((data, index) => {
          const Component = blockMap[data.name] || false

          if (!Component) {
            return null
          }

          return (
            <Component
              key={`${data.name}-${index}`}
              innerBlocks={data.innerBlocks}
              {...data.attributes}
            />
          )
        })}
    </div>
  )
}
