import React from "react"
import parse from "html-react-parser"
import classNames from "classnames"
import "./index.css"

export default ({ ystdbHeadingAttributes }) => {
  const content = ystdbHeadingAttributes.content
  const subText = ystdbHeadingAttributes.subText
  const level = ystdbHeadingAttributes.level
  const align = ystdbHeadingAttributes.align

  const levelClass = level ? "is-level-" + level : ""
  const alignClass = align ? "is-" + align : ""
  const ystdbHeadingClasses = classNames(
    "ystdb-heading",
    levelClass,
    alignClass
  )
  return (
    <div className={ystdbHeadingClasses}>
      {(() => {
        return React.createElement(
          `h${level}`,
          { className: "ystdb-heading-main" },
          parse(content)
        )
      })()}
      <p className="ystdb-heading-sub">{parse(subText)}</p>
    </div>
  )
}
