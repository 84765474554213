import React from "react"
import { graphql } from "gatsby"
import Apps from "../components/apps"
import AppHead from "../components/apps/head"
import Section from "../components/layouts/section"
import Grid from "../components/layouts/grid"
import Column from "../components/layouts/column"
import Article from "../components/layouts/article"
import Aside from "../components/layouts/aside"
import Blocks from "../components/blocks"
import Heading from "../components/elements/heading"
import Media from "../components/elements/media"
import Blank from "../components/elements/blank"

export default ({ data, location }) => {
  const emigration = data.wpgraphql.emigration
  const allEmigration = data.wpgraphql.allEmigration.edges
  const pageTitle = emigration.title
  const pageCover =
    emigration.featuredImage && emigration.featuredImage.localFile
      ? emigration.featuredImage
      : ""
  const excerpt = emigration.excerpt
    ? emigration.excerpt
        .replace(/<p>/g, "")
        .replace(/<\/p>/g, "")
        .replace(/\[&hellip;\]/g, "...")
    : ""
  //console.log(excerpt)
  return (
    <Apps>
      <AppHead
        pageTitle={pageTitle}
        pageDescription={excerpt}
        pagePath={location.pathname}
        pageOgImage={pageCover.localFile.publicURL}
      />
      <Section>
        <Grid media="desktop" sameHeight={true} list="1fr var(--sidebar-width)">
          <Column padding="md">
            <Article
              cover={pageCover}
              title={pageTitle}
              date={emigration.date}
              modified={emigration.modified}
            >
              <Blocks {...emigration} />
            </Article>
          </Column>
          <Column padding="md">
            <Aside stickyMedia="desktop" stickyOffset="80px">
              <Heading level="2" size="md" color="black">
                移住レポートシリーズ
              </Heading>
              <Grid media="mobile" list="1fr" gap="14px">
                {allEmigration.length !== 0 ? (
                  allEmigration.map((edge, i) => (
                    <Column key={i}>
                      <Media
                        cover={edge.node.featuredImage}
                        url={edge.node.uri}
                        title={edge.node.title}
                        here={true}
                      />
                    </Column>
                  ))
                ) : (
                  <Blank />
                )}
              </Grid>
            </Aside>
          </Column>
        </Grid>
      </Section>
    </Apps>
  )
}

export const query = graphql`
  query Emigration($id: ID!) {
    wpgraphql {
      emigration(id: $id) {
        title
        id
        date
        modified
        excerpt(format: RENDERED)
        featuredImage {
          altText
          sourceUrl
          localFile {
            publicURL
            childImageSharp {
              fluid(maxWidth: 620) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        blocks {
          ...AllBlocks
        }
      }
      allEmigration(where: { orderby: { field: DATE, order: ASC } }) {
        edges {
          node {
            title
            date
            uri
            featuredImage {
              altText
              sourceUrl
              localFile {
                childImageSharp {
                  fluid(maxHeight: 60) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
