import React from "react"
import Image from "gatsby-image"
import classNames from "classnames"
import Heading from "../../../components/elements/heading"
import Date from "../../../components/elements/date"
import "./index.css"

export default ({ cover, title, date, modified, children }) => {
  const articleClasses = classNames("article")
  return (
    <article className={articleClasses}>
      <header className="article-header">
        {cover && cover.localFile && (
          <Image
            className={`article-cover`}
            fluid={cover.localFile.childImageSharp.fluid}
            alt={cover.altText}
          />
        )}
        <Heading level="1" size="xxl" color="black">
          {title}
        </Heading>
        <div className="article-meta">
          <Date date={date} modified={modified} size="sm" />
        </div>
      </header>
      {children}
    </article>
  )
}
