import React from "react"
import parse from "html-react-parser"
import classNames from "classnames"
import Image from "gatsby-image"
import "./index.css"

export default ({
  balloonType,
  text,
  localFile: {
    childImageSharp: { fluid },
  },
}) => {
  const balloonTypeClass = balloonType ? "is-" + balloonType : ""
  const ystdbBalloonClasses = classNames("ystdb-baloon", balloonTypeClass)
  return (
    <div className={ystdbBalloonClasses}>
      <div className={"ystdb-balloon-avatar"}>
        {fluid && (
          <div className="ystdb-balloon-avatar-image">
            <Image
              className={`ystdb-balloon-avatar-image-media`}
              fluid={fluid}
              alt=""
            />
          </div>
        )}
      </div>
      <div className="ystdb-balloon-voice">
        {parse(text)}
        {balloonType === "think" ? (
          <>
            <span className="ystdb-balloon-voice-bubble"></span>
            <span className="ystdb-balloon-voice-bubble"></span>
          </>
        ) : (
          <span className="ystdb-balloon-voice-tail"></span>
        )}
      </div>
    </div>
  )
}
