import React from "react"
import classNames from "classnames"
import CoreParagraph from "./core/paragraph"
import CoreImage from "./core/image"
import CoreHeading from "./core/heading"
import CoreList from "./core/list"
import CoreQuote from "./core/quote"
import CoreSeparator from "./core/separator"
import CoreSpacer from "./core/spacer"
import CoreHtml from "./core/html"
import CoreColumns from "./core/columns"
import CoreReusable from "./core/reusable"
import QrokoCard from "./qroko/blog-card"
import SmbAlert from "./smb/alert"
import SmbList from "./smb/list"
import SmbBalloon from "./smb/balloon"
import SmbBox from "./smb/box"
import SmbAccordion from "./smb/accordion"
import YstdbHeading from "./ystdb/heading"
import YstdbButton from "./ystdb/button"
import YstdbBalloon from "./ystdb/balloon"
//import YstdbCard from "./ystdb/card"
import "./index.css"

export default ({ blocks }) => {
  const blocksClasses = classNames("blocks")
  const blockMap = {
    "core/paragraph": CoreParagraph,
    "core/image": CoreImage,
    "core/heading": CoreHeading,
    "core/list": CoreList,
    "core/quote": CoreQuote,
    "core/separator": CoreSeparator,
    "core/spacer": CoreSpacer,
    "core/html": CoreHtml,
    "core/columns": CoreColumns,
    "core/block": CoreReusable,
    "qroko-blocks/blog-card": QrokoCard,
    "snow-monkey-blocks/alert": SmbAlert,
    "snow-monkey-blocks/list": SmbList,
    "snow-monkey-blocks/balloon": SmbBalloon,
    "snow-monkey-blocks/box": SmbBox,
    "snow-monkey-blocks/accordion": SmbAccordion,
    "ystdb/heading": YstdbHeading,
    "ystdb/svg-button": YstdbButton,
    "ystdb/balloon": YstdbBalloon,
    //"ystdb/card": YstdbCard,
  }

  return (
    <div className={blocksClasses}>
      {blocks &&
        blocks.map((data, index) => {
          const Component = blockMap[data.name] || false

          if (!Component) {
            return null
          }

          return (
            <Component
              key={`${data.name}-${index}`}
              {...data.attributes}
              coreSpacerAttributes={data.coreSpacerAttributes}
              qrokoBlogCardAttributes={data.qrokoBlogCardAttributes}
              ystdbHeadingAttributes={data.ystdbHeadingAttributes}
              originalContent={data.originalContent}
              dynamicContent={data.dynamicContent}
              innerBlocks={data.innerBlocks}
              reusableBlock={data.reusableBlock}
            />
          )
        })}
    </div>
  )
}
