import React from "react"
import classNames from "classnames"
import SmbAccordionItem from "../accordion-item"
import "./index.css"

export default ({ innerBlocks }) => {
  const smbAccordionClasses = classNames("smb-accordion")
  const blockMap = {
    "snow-monkey-blocks/accordion--item": SmbAccordionItem,
  }
  return (
    <div className={smbAccordionClasses}>
      {innerBlocks &&
        innerBlocks.map((data, index) => {
          const Component = blockMap[data.name] || false

          if (!Component) {
            return null
          }

          return (
            <Component
              key={`${data.name}-${index}`}
              innerBlocks={data.innerBlocks}
              {...data.attributes}
            />
          )
        })}
    </div>
  )
}
