import React from "react"
import classNames from "classnames"
import "./index.css"

export default ({ level, size, color, en, children }) => {
  const Tag = "h" + level
  const sizeClass = size ? "is-" + size : ""
  const colorClass = color ? "is-" + color : ""
  const enClass = en ? "is-en" : ""
  const headingClasses = classNames("heading", sizeClass, colorClass, enClass)
  return <Tag className={headingClasses}>{children}</Tag>
}
