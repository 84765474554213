import React from "react"
import classNames from "classnames"
import "./index.css"

export default ({ stickyMedia, stickyOffset, children }) => {
  const stickyMediaClass = stickyMedia ? "is-" + stickyMedia + "-sticky" : ""
  const asideClasses = classNames("aside", stickyMediaClass)
  const stickyOffsetStyle = stickyOffset ? stickyOffset : ""
  const asideStyles = {
    top: stickyOffsetStyle,
  }
  return (
    <aside className={asideClasses} style={asideStyles}>
      {children}
    </aside>
  )
}
