import React from "react"
import parse from "html-react-parser"
import classNames from "classnames"
import Image from "gatsby-image"
import "./index.css"

export default ({
  alt,
  width,
  height,
  caption,
  align = "left",
  localFile: {
    childImageSharp: { fluid },
  },
}) => {
  const alignClass = align ? "is-" + align : ""
  const coreImageClasses = classNames("core-image", alignClass)
  const widthStyle = width ? width : ""
  const heightStyle = height ? height : ""
  const coreImageMediaStyles = {
    width: widthStyle,
    height: heightStyle,
  }
  return (
    <figure className={coreImageClasses}>
      {fluid && (
        <Image
          className={`core-image-media`}
          fluid={fluid}
          alt={alt}
          style={coreImageMediaStyles}
        />
      )}
      {caption && (
        <figcaption className={"core-image-caption"}>
          {parse(caption)}
        </figcaption>
      )}
    </figure>
  )
}
