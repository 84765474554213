import React from "react"
import parse from "html-react-parser"
import classNames from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import "./index.css"

export default ({ title, content, icon, modifier }) => {
  const modifierClass = modifier ? "is-" + modifier : ""
  const smbAlertClasses = classNames("smb-alert", modifierClass)
  return (
    <div className={smbAlertClasses}>
      <div className="smb-alert-title">
        <FontAwesomeIcon className="icon" icon={["fas", icon]} />
        <strong>{title}</strong>
      </div>
      <div className="smb-alert-body">{parse(content)}</div>
    </div>
  )
}
