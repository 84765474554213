import React from "react"
import parse, { domToReact } from "html-react-parser"
import classNames from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import "./index.css"

export default ({ values, ordered, className }) => {
  const listTag = ordered ? "ol" : "ul"
  const coreListClasses = classNames("core-list", className)
  const options = {
    replace: ({ attribs, children }) => {
      if (!attribs) return
      if (className === "is-style-sme-list-arrow") {
        if ("<li></li>") {
          return (
            <li>
              <FontAwesomeIcon className="icon" icon={["fas", "arrow-right"]} />
              {domToReact(children)}
            </li>
          )
        }
      }
      if (className === "is-style-sme-list-check") {
        if ("<li></li>") {
          return (
            <li>
              <FontAwesomeIcon className="icon" icon={["fas", "check"]} />
              {domToReact(children)}
            </li>
          )
        }
      }
      if (className === "is-style-sme-list-remark") {
        if ("<li></li>") {
          return (
            <li>
              <FontAwesomeIcon className="icon" icon={["fas", "asterisk"]} />
              {domToReact(children)}
            </li>
          )
        }
      }
      if (className === "is-style-sme-list-times") {
        if ("<li></li>") {
          return (
            <li>
              <FontAwesomeIcon className="icon" icon={["fas", "times"]} />
              {domToReact(children)}
            </li>
          )
        }
      }
    },
  }
  if (!values) {
    return null
  }
  return React.createElement(
    listTag,
    { className: coreListClasses },
    parse(values, options)
  )
}
